<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom>
      <template #buttons>
        <el-button type="success" size="mini" @click="jumpAdd">新增</el-button>
        <el-button type="danger" size="mini" @click="itemDelete">删除</el-button>
        <el-button type="primary" size="mini" @click="itemEdit">查看 </el-button>
        <el-button type="primary" size="mini" @click="itemPrint">打印 </el-button>
        <el-button type="primary" size="mini" @click="itemPush">提交审核 </el-button>
        <el-button
          type="danger"
          size="mini"
          @click="itemReject"
          v-if="$tool.hasControl(['/gcvip/checkBill/leaderReject'])"
        >
          审核拒绝
        </el-button>
        <el-button
          type="success"
          size="mini"
          @click="itemPass"
          v-if="$tool.hasControl(['/gcvip/checkBill/leaderDoAudit'])"
        >
          审核通过
        </el-button>
        <el-button type="primary" size="mini" @click="itemQuote">生成报价单</el-button>
        <el-button type="primary" size="mini" @click="itemContract">生成销售合同</el-button>
        <el-button type="primary" size="mini" @click="itemSk">开收款单</el-button>
        <el-button type="primary" size="mini" @click="itemBx">关联报销</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      :merge-cells="mergeCell"
      @cell-dblclick="
        ({ row }) => {
          itemEdit(row.bill_id)
        }
      "
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_status_name="{ row }">
        <span :style="{ color: statusStyle(row) }"> {{ row.status_name }} </span>
      </template>
      <template #default_liuchen_biaozhun>
        <el-link type="primary"> 预览 </el-link>
      </template>
      <template #default_photo_list="{ row }">
        <e-upload-image v-if="row.photo_list" :list.sync="row.photo_list" size="30px" disabled />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 开收款单 -->
    <PopupSk
      v-if="isShowPopupSk"
      :show.sync="isShowPopupSk"
      :billId="activeBillId"
      @success="searchListData"
    />

    <!-- 开收款单 -->
    <PopupBx
      v-if="isShowPopupBx"
      :show.sync="isShowPopupBx"
      :billId="activeBillId"
      @success="searchListData"
    />
  </div>
</template>

<script>
import PopupSk from '../../../common/popup-sk'
import PopupBx from '../../../common/popup-bx'
export default {
  components: {
    PopupSk,
    PopupBx
  },
  data() {
    return {
      loaded: false,

      // filter
      filterData: {
        bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today],
        is_delete: false
      },
      sortData: {
        create_date: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/checkBill/searchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 50
        },
        {
          title: 'ID',
          field: 'bill_id',
          isMerge: true,
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '销售日期',
          field: 'bill_date',
          width: 210,
          isMerge: true,
          slots: { header: 'header_daterange' }
        },
        {
          title: '销售单号',
          field: 'bill_code',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '客户名称',
          field: 'company_name',
          width: 240,
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '审核状态',
          field: 'status_name',
          isMerge: true,
          slots: {
            header: 'header_autocomplete',
            default: 'default_status_name'
          }
        },
        {
          title: '订单销售金额',
          field: 'sales_amount_total',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '订单利润',
          field: 'profit_total',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'profit_rate_total',
          title: '订单利润率',
          isMerge: true,
          slots: { header: 'header_autocomplete' },
          formatter: ({ cellValue }) => {
            if (this.$util.isBlankVue(cellValue)) {
              return ''
            } else {
              return cellValue + '%'
            }
          }
        },
        {
          title: '生产流程/标准',
          field: 'liuchen_biaozhun',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_liuchen_biaozhun' }
        },
        {
          title: '产品图片',
          field: 'photo_list',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_photo_list' }
        },
        {
          title: '备注',
          field: 'bill_remark',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page,
        pageSize: 500
      },

      activeBillId: null,
      isShowPopupSk: false,
      isShowPopupBx: false
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    statusStyle(row) {
      let result = ''
      if (row.status === 0) result = '#999'
      if (row.status === 1) result = '#66b1ff'
      if (row.status === 2) result = '#67c23a'
      return result
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loaded = false
      this.$api({
        method: 'post',
        url: '/admin/gcvip/checkBill/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData,
          isAccurate: false
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'bill_id'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['sales_amount_total', 'profit_total', 'profit_rate_total'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 跳转到-新增
    jumpAdd() {
      this.$router.push('/sale/order/detail')
    },
    // 项-编辑
    itemEdit() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemEditAction(row.id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-编辑-执行
    itemEditAction(id) {
      this.$router.push(`/sale/order/detail?id=${id}`)
    },
    // 项-打印
    itemPrint() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPrintAction(row.bill_id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-打印-执行
    itemPrintAction(id) {
      window.open(`/sale/order/print?id=${id}`, '_blank')
    },
    // 项-删除
    itemDelete() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemDeleteAction(row.bill_id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-删除-执行
    itemDeleteAction(id) {
      this.$confirm(`id=${id}，是否删除该核算单?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.itemDeleteConfirm(id)
      })
    },
    // 项-删除-确认
    itemDeleteConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/doDelete',
        params: {
          id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.$message.success(res.data.msg)
            this.getListData()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    },
    // 项-提交审核
    itemPush() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPushConfirm(row.bill_id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-提交审核-确认
    itemPushConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/salerDoAudit',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已提交')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核拒绝
    itemReject() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemRejectAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审核拒绝
    itemRejectAction(row) {
      this.$confirm(`销售单号：${row.bill_code}，该核算单是否驳回审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.itemRejectConfirm(row.bill_id)
      })
    },
    // 项-审核拒绝-确认
    itemRejectConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderReject',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已拒绝')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核通过
    itemPass() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPassAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审核通过
    itemPassAction(row) {
      this.$confirm(`销售单号：${row.bill_code}，该核算单是否通过审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.itemPassConfirm(row.bill_id)
      })
    },
    // 项-审核通过-确认
    itemPassConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/checkBill/leaderDoAudit',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已通过')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-生成报价单
    itemQuote() {
      let row = this.getRadioRecord()
      if (row) {
        window.open(`/sale/quote/print?id=${row.bill_id}`, '_blank')
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-生成销售合同
    itemContract() {
      let row = this.getRadioRecord()
      if (row) {
        window.open(`/sale/contract/print?id=${row.bill_id}`, '_blank')
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-开收款单
    itemSk() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeBillId = row.bill_id
        this.isShowPopupSk = true
      } else {
        this.activeBillId = null
        this.itemChoosePrompt()
      }
    },
    // 项-关联报销
    itemBx() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeBillId = row.bill_id
        this.isShowPopupBx = true
      } else {
        this.activeBillId = null
        this.itemChoosePrompt()
      }
    },
    // 项-引用新增
    itemCopyAdd() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemEditAction(row.id)
      } else {
        this.itemChoosePrompt()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
